import { useEffect } from 'react'
// import { navigate } from "@reach/router"
import { navigate } from "gatsby" 

const Account = () => {
	useEffect(() => {
		navigate('/account/profile');
	}, []);
	return null;
}

export default Account